import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { memo } from "react";

/** Dropdown - filter status */
const FieldStatus = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const handleChange = (ev: SelectChangeEvent) => {
    onChange(ev.target.value);
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel>Status</InputLabel>
        <Select label="Status" value={value} onChange={handleChange}>
          <MenuItem value="">All</MenuItem>
          {/* TODO: enum */}
          <MenuItem value="Lead">Lead</MenuItem>
          <MenuItem value="Member">Member</MenuItem>
          <MenuItem value="Buyer">Buyer</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default memo(FieldStatus);
