import { gql, useQuery } from "@apollo/client";
import { Box, Link, Popover, Skeleton } from "@mui/material";
import { Theme } from "@mui/material/styles";
import React, { memo, MouseEvent } from "react";
import { UnitData } from "../../types";

const QUERY_UNIT = gql`
  query Unit($id: ID) {
    unit(ID: $id) {
      SYS_B_ID
      Projekt_nr
      Vejnavn
      Husnr
      Floor {
        FloorName
      }
      Sidedoor
      Post_Nr_By
      Images {
        Link
      }
    }
  }
`;

const LogMatchedUnits = ({
  unitIds,
  percentage,
}: {
  unitIds: string[];
  percentage: number;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const title = `${unitIds.length} nye ${
    unitIds.length > 1 ? "boliger" : "bolig"
  } matchet`;

  return (
    <>
      <>
        {title} (
        <Link href="#" onClick={handleClick} sx={{ textDecoration: "none" }}>
          Info
        </Link>
        )
      </>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            width: 400,
            bgcolor: "background.paper",
          }}
        >
          <Box sx={{ p: 2 }}>{title}</Box>
          {/* units */}
          <Box
            sx={{
              maxHeight: 261,
              overflowX: "auto",
              "> .MuiBox-root:nth-of-type(odd)": {
                backgroundColor: "#F9FAFA",
              },
            }}
          >
            {unitIds.map((unitId, i) => (
              <Box key={unitId} sx={{ px: 2, py: 2 }}>
                <UnitRow unitId={unitId} percentage={percentage} />
              </Box>
            ))}
          </Box>
        </Box>
      </Popover>
    </>
  );
};

const UnitRow = memo(
  ({ unitId, percentage }: { unitId: string; percentage: number }) => {
    // get unit.
    const { data, loading } = useQuery<{ unit?: UnitData }, { id: string }>(
      QUERY_UNIT,
      {
        variables: {
          id: unitId,
        },
      }
    );

    return (
      <Box sx={{ display: "flex" }}>
        {/* image */}
        <Box sx={{ width: 55, height: 55 }}>
          {loading ? (
            <Skeleton
              variant="rectangular"
              sx={{ height: "100%", borderRadius: "4px" }}
            />
          ) : (
            <>
              {data?.unit?.Images && data?.unit?.Images[0] ? (
                <Box
                  component="img"
                  src={data?.unit?.Images[0].Link}
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "4px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    bgcolor: (theme: Theme) => theme.palette.grey[300],
                    borderRadius: "4px",
                  }}
                />
              )}
            </>
          )}
        </Box>
        {/* address */}
        <Box sx={{ flex: "1 1 auto", px: 2 }}>
          {loading ? (
            <Skeleton variant="text" width="50%" />
          ) : (
            <Box fontWeight="600">{data?.unit?.Projekt_nr}</Box>
          )}
          {loading ? (
            <Skeleton variant="text" />
          ) : (
            <div>
              {data?.unit?.Vejnavn} {data?.unit?.Husnr},{" "}
              {data?.unit?.Floor?.FloorName} {data?.unit?.Sidedoor}
            </div>
          )}
        </Box>
        {/* percentage */}
        <Box
          sx={{
            width: 55,
          }}
        >
          {loading ? (
            <Skeleton
              variant="rectangular"
              sx={{ height: 55, borderRadius: "8px" }}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: 55,
                borderRadius: "8px",
                textAlign: "center",
                fontWeight: 600,
                backgroundColor: percentage > 90 ? "#D2E5D0" : "#FFFFFF",
                border: "1px solid",
                borderColor: percentage > 90 ? "transparent" : "#ECEDEC",
              }}
            >
              {percentage}%
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);

export { LogMatchedUnits };
