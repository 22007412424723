import {
  Box,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

type TablePaginationProps = {
  page?: number;
  perPage?: number;
  total?: number;
  onPageChange?: (page: number) => void;
  onPerPageChange?: (perPage: number) => void;
};

const TablePagination = ({
  page = 1,
  perPage = 10,
  total = 0,
  onPageChange = (page: number) => {},
  onPerPageChange = (perPage: number) => {},
}: TablePaginationProps) => {
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(10);

  useEffect(() => {
    setStart((page - 1) * perPage + 1);
    const endCount = page * perPage;
    setEnd(endCount > total ? total : endCount);
  }, [page, perPage, total]);

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{
        py: 2.5,
      }}
    >
      <Typography fontSize={13}>
        Vis {start}-{end} af {total}
      </Typography>
      {total > 0 && (
        <Box>
          <Pagination
            count={Math.ceil(total / perPage)}
            page={page}
            onChange={(event, page) => onPageChange(page)}
            shape="rounded"
            variant="outlined"
          />
        </Box>
      )}
      <Stack direction="row" alignItems="center">
        <Typography mr={2} fontSize={13}>
          Vis
        </Typography>
        <Select
          value={perPage}
          onChange={(event) => onPerPageChange(event.target.value as number)}
          size="small"
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
        </Select>
      </Stack>
    </Stack>
  );
};

export default TablePagination;
