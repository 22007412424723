import { Close } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { StyledChipOption } from "../../../../../theme/styled-components";
import { useData } from "../../../context/data";

const FieldPeferences = ({
  selected,
  onSelect,
}: {
  selected: string[];
  onSelect: (value: string[]) => void;
}) => {
  const { preferences } = useData();

  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;
    onSelect(typeof value === "string" ? value.split(",") : value);
  };

  const handleDelete = (item: string) => {
    onSelect(selected.filter((_item) => item !== _item));
  };

  return (
    <Box mb={4}>
      <Typography variant="sectionTitle" mb={2.5}>
        Præferencer
      </Typography>
      <FormControl fullWidth sx={{ maxWidth: 220 }}>
        <InputLabel>Vælg præferencer</InputLabel>
        <Select
          value={selected}
          label="Vælg præferencer"
          onChange={handleChange}
          multiple
          // renderValue={(selected) =>
          //   preferences
          //     .reduce<string[]>((filtered, option) => {
          //       if (selected.includes(option.MPPQ_ID)) {
          //         filtered.push(option.prefix);
          //       }
          //       return filtered;
          //     }, [])
          //     .join(", ")
          // }
          // renderValue={(selected) => `Vælg præferencer (${selected.length})`}
          renderValue={() => ""}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 250,
                width: 380,
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {preferences.map((option) => (
            <MenuItem key={option.MPPQ_ID} value={option.MPPQ_ID}>
              <Checkbox checked={selected.includes(option.MPPQ_ID)} />
              <ListItemText primary={option.prefix} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* chips */}
      <Box pt={3}>
        <Stack direction="row" flexWrap="wrap">
          {selected.map((value) => (
            <StyledChipOption
              key={value}
              label={
                preferences.find((preference) => preference.MPPQ_ID === value)
                  ?.prefix
              }
              variant="outlined"
              onDelete={() => handleDelete(value)}
              deleteIcon={<Close />}
              sx={{
                width: "auto",
                mr: 1,
                mb: 1,
                backgroundColor: "#e5e5e5",
                "& .MuiChip-deleteIcon": {
                  color: "#888888",
                  fontSize: 18,
                },
              }}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default memo(FieldPeferences);
