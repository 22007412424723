import { ApolloError, gql, useMutation } from "@apollo/client";
import { Box, Button, Modal as MuiModal, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAddActor } from "../../context/add";
import { Actor } from "../../types";
import FormAdd from "./FormAdd";
import { FormValues } from "./types";

const sxBox: SxProps<Theme> = {
  position: "absolute",
  left: "50%",
  top: "50%",
  width: "95%",
  maxWidth: "1080px",
  bgcolor: "background.paper",
  transform: "translate(-50%, -50%)",
  borderRadius: 2,
  border: "1px solid #F4F5F5",
  borderColor: "divider",
  outline: "none",
};

type ActorInput = {
  Firstname: string;
  Lastname: string;
  Username: string;
  EmailPrivate: string;
  PhonePrivate: string;
  Street: string;
  HouseNo: string;
  Floor: string;
  SideDoor: string;
  ZipCode: string;
  City: string;
  Country: string | null;
  Active: boolean;
  AutoMatch: boolean;
};

const MUTATION_ACTOR_DATA = gql`
  mutation Actor($actor: ActorInput) {
    Actor(actor: $actor) {
      SYS_A_ID
    }
  }
`;

const formSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const initialValues: FormValues = {
  firstName: "",
  lastName: "",
  username: "",
  phone: "",
  email: "",
  street: "",
  houseNumber: "",
  floor: "",
  sideDoor: "",
  zip: "",
  city: "",
  country: null,
  active: false,
  autoMatch: false,
};

const ModalAdd = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { success, error } = useAddActor();

  // gql
  const [addActor] = useMutation<
    { Actor: Pick<Actor, "SYS_A_ID"> },
    { actor: ActorInput }
  >(MUTATION_ACTOR_DATA);

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema: formSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values: FormValues) => {
    formik.setSubmitting(true);
    addActor({
      variables: {
        actor: {
          Firstname: values.firstName,
          Lastname: values.lastName,
          Username: values.username,
          EmailPrivate: values.email,
          PhonePrivate: values.phone,
          Street: values.street,
          HouseNo: values.houseNumber,
          Floor: values.floor,
          SideDoor: values.sideDoor,
          ZipCode: values.zip,
          City: values.city,
          Country: values.country ?? null,
          Active: values.active,
          AutoMatch: values.autoMatch,
        },
      },
    })
      .then((res) => {
        success(res.data?.Actor.SYS_A_ID ?? "");
      })
      .catch((er: ApolloError) => {
        error(er.message);
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  return (
    <MuiModal open={open} hideBackdrop={false}>
      <Box sx={sxBox}>
        <Box sx={{ position: "relative" }}>
          <Box sx={{ height: 500, overflowY: "auto" }}>
            <FormAdd formik={formik} />
          </Box>
          {/* modal footer */}
          <Box
            sx={{
              px: 2,
              py: 1.5,
              bgcolor: (theme: Theme) => theme.palette.grey[50],
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="default"
              onClick={onClose}
              disabled={formik.isSubmitting}
            >
              ANNULLER
            </Button>
            <Button
              variant="contained"
              onClick={formik.submitForm}
              disabled={formik.isSubmitting}
            >
              GEM
            </Button>
          </Box>
        </Box>
      </Box>
    </MuiModal>
  );
};

export default ModalAdd;
