import { Box, Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import { useData } from "../../../context/data";
import OptionChip from "../../OptionChip";
import QuestionsSkeleton from "./QuestionsSkeleton";
import Slider from "@mui/material/Slider";

const FieldHousing = ({
  selected,
  onSlide,
  onSelect,
  state,
}: {
  selected: string[];
  onSlide: (e: Event) => void;
  onSelect: (value: string) => void;
  state: any;
}) => {
  const { questionGroup } = useData();
  return (
    <Box mb={4}>
      <Typography variant="sectionTitle" mb={2.5}>
        Boligen
      </Typography>
      {questionGroup === null && <QuestionsSkeleton />}
      {questionGroup?.housing.map((question) => {
        return (
          <Box key={question.MPPQ_ID} mb={2.5}>
            <Typography variant="sectionDesc" mb={1.25}>
              {question.Prefix}
            </Typography>
            <Grid container spacing={1.5}>
              {((question.MPQT_ID === "107" || question.MPQT_ID === "103") &&
                question.Properties.Options.map((option, i) => {
                  const answerValue = `${question.MPPQ_ID}_${option.Value}`;
                  return (
                    <Grid
                      key={answerValue}
                      item
                      xs={6}
                      sm={3}
                      md={4}
                      lg={3}
                      xl={2.4}
                    >
                      <OptionChip
                        label={option.ShowValue}
                        value={answerValue}
                        selected={selected.includes(answerValue)}
                        handleClick={onSelect}
                      />
                    </Grid>
                  );
                })) ||
                (question.MPQT_ID === "105" && (
                  <Slider
                    getAriaLabel={() => question.Prefix}
                    value={state[question.MPPQ_ID]?.Values}
                    min={state[question.MPPQ_ID]?.Min}
                    step={state[question.MPPQ_ID]?.step}
                    max={state[question.MPPQ_ID]?.Max}
                    name={question.MPPQ_ID}
                    onChange={onSlide}
                    valueLabelDisplay="on"
                    disableSwap
                  />
                ))}
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(FieldHousing);
