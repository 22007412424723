import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

const Dashboard: React.FC = ({ children }) => {
  return (
    <Box
      pl={
        {
          // lg: "280px",
        }
      }
      pt="60px"
    >
      <Box p={2.5}>
        {children}
        <Outlet />
      </Box>
    </Box>
  );
};

export default Dashboard;
