import { ChipProps } from "@mui/material";
import React, { memo } from "react";
import { StyledChipOption } from "../../../theme/styled-components";

type OptionChipProps = ChipProps & {
  label: string;
  value: string;
  selected: boolean;
  handleClick: (value: string) => void;
};

const OptionChip = ({
  label,
  value,
  selected,
  handleClick,
  sx,
  ...props
}: OptionChipProps) => {
  const handleClickChip = () => {
    handleClick(value);
  };

  return (
    <StyledChipOption
      label={label}
      sx={{
        backgroundColor: selected ? "#e5e5e5" : "#ffffff",
        "&.MuiChip-clickable:hover": {
          backgroundColor: selected ? "#e0e0e0" : "rgba(0,0,0,0.04)",
        },
        ...sx,
      }}
      onClick={handleClickChip}
      variant="outlined"
      {...props}
    />
  );
};

export default memo(OptionChip);
