import React from "react";
import { Navigate, RouteObject } from "react-router-dom";
import DashboardLayout from "./layouts/Dashboard";
import ActorsPage from "./modules/actors/ActorsPage";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="actors" />,
      },
      {
        path: "actors/*",
        element: <ActorsPage />,
      },
      {
        path: "*",
        element: <>Not Found</>,
      },
    ],
  },
];

export default routes;
