import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  Add,
  Close,
  ThumbDownAlt,
  ThumbDownOffAlt,
  ThumbUpAlt,
  ThumbUpOffAlt,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { format, parseISO } from "date-fns";
import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useEdit } from "../../context/edit";
import { Actor, Unit } from "../../types";

// gql
const QUERY_ACTOR = gql`
  query Actor($id: ID) {
    actor(ID: $id) {
      SYS_A_ID
      Matches {
        MPMU_ID
        AutomatchedDate
        AutomatchedPercentage
        ShowToUser
        UserLikes
        UserDislikes
        DislikeComment
        SeenByMember
        Match_Data {
          SYS_B_ID
          Vejnavn
          Etage
          Floor {
            FloorName
          }
          Husnr
          Sidedoor
          Post_Nr_By
          Salg_BBR_kvm
          Salg_kontant_pris
          Kvm
          Leje_RAW
          Web_Liste
          Projekt_nr
          Type {
            Text_1_DK
          }
          Images {
            Link
          }
        }
      }
    }
  }
`;

const MUTATION_UNIT = gql`
  mutation UnitMutation($match: MatchInput) {
    ActorMatch(match: $match) {
      MPMU_ID
      ShowToUser
      UserLikes
      UserDislikes
    }
  }
`;

type ActorUnits = Pick<Actor, "SYS_A_ID" | "Matches">;

type UnitInput = Pick<Unit, "MPMU_ID"> &
  Partial<Pick<Unit, "ShowToUser" | "UserLikes" | "UserDislikes">>;

type UnitsContextType = {
  units: Unit[];
  userUnits: Unit[];
  updateShow: (unit: Unit, show: boolean) => Promise<Partial<Unit>>;
  updateData: (data: UnitInput) => Promise<Partial<Unit>>;
  loading: boolean;
};

export const UnitsContext = createContext<UnitsContextType>({
  units: [],
  userUnits: [],
  updateShow: () => new Promise(() => {}),
  updateData: () => new Promise(() => {}),
  loading: false,
});

const UnitsProvider: React.FC = ({ children }) => {
  const { actorId } = useEdit();

  const [actor, setActor] = useState<ActorUnits | null | undefined>(null);
  const [units, setUnits] = useState<Unit[]>([]);
  const [userUnits, setUserUnits] = useState<Unit[]>([]);
  const [loading, setLoading] = useState(false);

  // gql.
  const [getActor] = useLazyQuery<{ actor: ActorUnits | null }, { id: string }>(
    QUERY_ACTOR
  );

  const [updateUnit] = useMutation<
    { ActorMatch: Pick<Unit, "ShowToUser" | "UserLikes" | "UserDislikes"> },
    { match: UnitInput }
  >(MUTATION_UNIT);

  useEffect(() => {
    if (actorId) {
      // fetch data.
      setLoading(true);
      getActor({
        variables: {
          id: actorId,
        },
      })
        .then((res) => {
          setActor(res?.data?.actor);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [actorId, getActor]);

  const submitUnit = (data: UnitInput) => {
    return updateUnit({
      variables: {
        match: data,
      },
    });
  };

  const updateShow = (unit: Unit, show: boolean): Promise<Partial<Unit>> => {
    return submitUnit({
      MPMU_ID: unit.MPMU_ID,
      ShowToUser: show,
    }).then((res) => {
      unit = { ...unit, ShowToUser: show };
      if (show) {
        // remove from left
        setUnits((units) => units.filter((u) => unit.MPMU_ID !== u.MPMU_ID));
        // add to right
        setUserUnits((units) => [unit, ...units]);
      } else {
        // remove from right
        setUserUnits((units) =>
          units.filter((u) => unit.MPMU_ID !== u.MPMU_ID)
        );
        // add to left
        setUnits((units) => [unit, ...units]);
      }
      return res.data ? res.data?.ActorMatch : {};
    });
  };

  const updateData = async (data: UnitInput) => {
    return submitUnit(data).then((res) => {
      return res.data ? res.data?.ActorMatch : {};
    });
  };

  // separate units
  useEffect(() => {
    if (actor?.Matches) {
      setUnits(actor?.Matches.filter((unit) => !unit.ShowToUser));
      setUserUnits(actor?.Matches.filter((unit) => unit.ShowToUser));
    } else {
      setUnits([]);
      setUserUnits([]);
    }
  }, [actor]);

  return (
    <UnitsContext.Provider
      value={{
        units,
        userUnits,
        updateShow,
        updateData,
        loading,
      }}
    >
      <>{children}</>
    </UnitsContext.Provider>
  );
};

const useUnits = () => useContext(UnitsContext);

const MatchedUnits = () => {
  return (
    <Box p={2.5}>
      <UnitsProvider>
        <Grid container spacing={3}>
          {/* units */}
          <Grid item md={6} xs={12}>
            <Units />
          </Grid>

          {/* units that show to user */}
          <Grid item md={6} xs={12}>
            <UserUnits />
          </Grid>
        </Grid>
      </UnitsProvider>
    </Box>
  );
};

// unit list with show to user is false
const Units = () => {
  const { units, loading, updateShow, updateData } = useUnits();

  const handleUpdateShow = useCallback((unit: Unit, show: boolean) => {
    return updateShow(unit, show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateData = useCallback((data: UnitInput) => {
    return updateData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="sectionTitle" mb={2.5}>
        Alle enheder
      </Typography>

      <Box
        sx={{
          backgroundColor: "#ECEDEC",
          borderRadius: "8px",
        }}
      >
        <Box p={2.5}>
          <FilterForm />
        </Box>

        <Divider sx={{ borderColor: "#E5E5E5" }} />

        <Box p={2.5}>
          {loading ? (
            <>
              {[1, 2].map((i) => (
                <Box key={i} mb={2.5}>
                  <UnitCardSkeleton />
                </Box>
              ))}
            </>
          ) : (
            <>
              {units.map((unit) => (
                <Box key={unit.MPMU_ID} mb={2.5}>
                  <UnitCard
                    unit={unit}
                    updateShow={handleUpdateShow}
                    updateData={handleUpdateData}
                  />
                </Box>
              ))}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const FilterForm = () => {
  const [selected, setSelected] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value as string);
  };
  return (
    <Grid container spacing={1.5}>
      <Grid item xs={9}>
        <Box>
          <TextField
            label="Søg på navn, adresse, projekt..."
            fullWidth
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel>Sorter</InputLabel>
          <Select value={selected} label="Sorter" onChange={handleChange}>
            <MenuItem value="">All</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

// unit list with show to user is true
const UserUnits = () => {
  const { userUnits: units, loading, updateShow, updateData } = useUnits();

  const handleUpdateShow = useCallback((unit: Unit, show: boolean) => {
    return updateShow(unit, show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateData = useCallback((data: UnitInput) => {
    return updateData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="sectionTitle" mb={2.5}>
        Synlige enheder for medlem
      </Typography>

      <Box
        sx={{
          backgroundColor: "#ECEDEC",
          borderRadius: "8px",
        }}
      >
        <Box p={2.5}>
          {loading ? (
            <Box mb={2.5}>
              <UnitCardSkeleton />
            </Box>
          ) : (
            <>
              {units.map((unit) => (
                <Box key={unit.MPMU_ID} mb={2.5}>
                  <UnitCard
                    unit={unit}
                    updateShow={handleUpdateShow}
                    updateData={handleUpdateData}
                  />
                </Box>
              ))}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const UnitCard = memo(
  ({
    unit: unitData,
    updateShow,
    updateData,
  }: {
    unit: Unit;
    updateShow: (unit: Unit, show: boolean) => Promise<Partial<Unit>>;
    updateData: (data: UnitInput) => Promise<Partial<Unit>>;
  }) => {
    const [unit, setUnit] = useState(unitData);
    const [disableButton, setdisableButton] = useState(false);

    const [commentAnchorEl, setCommentAnchorEl] =
      React.useState<HTMLButtonElement | null>(null);

    const handleCommentClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setCommentAnchorEl(event.currentTarget);
    };

    const handleCommentClose = () => {
      setCommentAnchorEl(null);
    };

    const handleUnitShow = () => {
      setdisableButton(true);
      updateShow(unit, true);
    };

    const handleUnitHide = () => {
      setdisableButton(true);
      updateShow(unit, false);
    };

    const handleLike = () => {
      setdisableButton(true);
      updateData({
        MPMU_ID: unit.MPMU_ID,
        UserLikes: !unit.UserLikes,
        UserDislikes: false,
      }).then((res) => {
        setUnit((unit) => ({ ...unit, ...res }));
        setdisableButton(false);
      });
    };

    const handleDislike = () => {
      setdisableButton(true);
      updateData({
        MPMU_ID: unit.MPMU_ID,
        UserLikes: false,
        UserDislikes: !unit.UserDislikes,
      }).then((res) => {
        setUnit((unit) => ({ ...unit, ...res }));
        setdisableButton(false);
      });
    };

    const open = Boolean(commentAnchorEl);

    return (
      <Card
        sx={{
          border: unit.edited ? "2px dashed rgba(0,0,0,0.3)" : "none",
        }}
      >
        <Box bgcolor="#D2E5D0" px={1.5} py={1}>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              {unit.Match_Data?.Projekt_nr}
            </Grid>
            <Grid item xs={2} sx={{ textAlign: "center", fontWeight: "600" }}>
              {unit.AutomatchedPercentage}%
            </Grid>
            <Grid item xs={5} sx={{ textAlign: "right" }}>
              {unit.ShowToUser ? (
                <IconButton onClick={handleUnitHide} disabled={disableButton}>
                  <Close />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  size="small"
                  onClick={handleUnitShow}
                  disabled={disableButton}
                >
                  TILFØJ
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box p={1.5}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {/* img */}
              {unit.Match_Data?.Images && unit.Match_Data?.Images[0] ? (
                <Box
                  component="img"
                  src={unit.Match_Data?.Images[0].Link}
                  sx={{ width: "100%", borderRadius: "4px" }}
                />
              ) : (
                <Box
                  sx={{
                    pt: "75%",
                    bgcolor: (theme: Theme) => theme.palette.grey[300],
                    borderRadius: "4px",
                  }}
                />
              )}
            </Grid>
            <Grid item xs={8}>
              <Typography component="div" fontSize={13} fontWeight={500}>
                {unit.Match_Data?.Vejnavn} {unit.Match_Data?.Husnr},{" "}
                {unit.Match_Data?.Floor?.FloorName} {unit.Match_Data?.Sidedoor}
              </Typography>
              <Typography component="div" fontSize={11}>
                {unit.Match_Data?.Post_Nr_By}
              </Typography>
              <Typography component="div" fontSize={12} sx={{ py: 0.5 }}>
                {unit.Match_Data?.Type?.Text_1_DK && (
                  <div>{unit.Match_Data?.Type?.Text_1_DK}</div>
                )}
                <div>
                  {unit.Match_Data?.Kvm} m<sup>2</sup>
                </div>
                {/* sale */}
                <div>
                  {unit.Match_Data?.Web_Liste === "1" &&
                    unit.Match_Data?.Salg_kontant_pris && (
                      <>{unit.Match_Data?.Salg_kontant_pris} kr.</>
                    )}
                  {/* rent */}
                  {unit.Match_Data?.Web_Liste === "2" &&
                    unit.Match_Data?.Leje_RAW && (
                      <>
                        {Intl.NumberFormat("da-DA").format(
                          unit.Match_Data?.Leje_RAW
                        )}{" "}
                        kr./mdr
                      </>
                    )}
                </div>
              </Typography>
              {unit.DislikeComment && (
                <>
                  <Box fontSize={12}>
                    Kommentar (
                    <Typography
                      component="span"
                      color="#7BB3BD"
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={handleCommentClick}
                    >
                      vis
                    </Typography>
                    )
                  </Box>
                  <Popover
                    open={open}
                    anchorEl={commentAnchorEl}
                    onClose={handleCommentClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography sx={{ p: 2 }}>{unit.DislikeComment}</Typography>
                  </Popover>
                </>
              )}
            </Grid>
          </Grid>
        </Box>

        <Divider />

        <Box px={1.5} py={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row">
              <Chip
                label={unit.SeenByMember ? "SET" : "IKKE SET"}
                size="small"
                sx={{
                  backgroundColor: unit.SeenByMember ? "#D7EDED" : "#E5D0D0",
                  color: "#000000",
                  mt: 1,
                }}
              />
              <Box ml={1.5}>
                <IconButton onClick={handleLike} disabled={disableButton}>
                  {unit.UserLikes ? (
                    <ThumbUpAlt sx={{ fontSize: 20 }} color="primary" />
                  ) : (
                    <ThumbUpOffAlt sx={{ fontSize: 20 }} />
                  )}
                </IconButton>
                <IconButton onClick={handleDislike} disabled={disableButton}>
                  {unit.UserDislikes ? (
                    <ThumbDownAlt sx={{ fontSize: 20 }} color="primary" />
                  ) : (
                    <ThumbDownOffAlt sx={{ fontSize: 20 }} />
                  )}
                </IconButton>
              </Box>
            </Stack>
            <Typography component="div" sx={{ fontSize: 12 }}>
              {unit.AutomatchedDate &&
                format(parseISO(unit.AutomatchedDate), "d.M.yyyy")}
            </Typography>
          </Stack>
        </Box>
      </Card>
    );
  }
);

const UnitCardSkeleton = memo(() => {
  return (
    <Card>
      <Box bgcolor="#D2E5D0" px={1.5} py={1}>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <Skeleton width={50} />
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "center", fontWeight: "600" }}>
            <Skeleton width={50} />
          </Grid>
          <Grid item xs={5} sx={{ textAlign: "right" }}>
            <Skeleton width={50} sx={{ display: "inline-block" }} />
          </Grid>
        </Grid>
      </Box>

      <Box p={1.5}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {/* img */}
            <Skeleton
              variant="rectangular"
              sx={{
                height: 0,
                borderRadius: "4px",
                pt: "75%",
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography component="div" fontSize={13} fontWeight={500}>
              <Skeleton width={100} />
            </Typography>
            <Typography component="div" fontSize={11}>
              <Skeleton width={100} />
            </Typography>
            <Typography component="div" fontSize={12} sx={{ py: 0.5 }}>
              <Skeleton width={100} />
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box px={1.5} py={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row">
            <div>
              <Skeleton width={50} />
            </div>
            <Box ml={1.5}>
              <Skeleton width={50} />
            </Box>
          </Stack>
          <div>
            <Skeleton width={50} />
          </div>
        </Stack>
      </Box>
    </Card>
  );
});

export default MatchedUnits;
