import { Chip, ChipProps, Table, TableProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTable = styled(Table)<TableProps>(({ theme }) => ({
  fontSize: 13,
  "& .MuiTableCell-head": {
    fontSize: "inherit",
    borderBottom: "none",
  },
  "& .MuiTableCell-body": {
    fontSize: "inherit",
    borderBottom: "none",
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
    },
  },
}));

export const StyledOverviewTable = styled(Table)<TableProps>(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontSize: 13,
  },
  "& .MuiTableCell-footer, & .MuiTableCell-head": {
    borderBottom: "none",
    fontWeight: 500,
    color: "inherit",
  },
  "& .MuiTableCell-body": {
    borderBottom: "none",
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
    },
  },
}));

export const StyledChip = styled(Chip)<ChipProps>(() => ({
  backgroundColor: "#F5D8D2",
  borderRadius: "4px",
  fontWeight: 600,
  height: 24,
  fontSize: 12,
}));

export const StyledChipOption = styled(Chip)<ChipProps>(() => ({
  width: "100%",
  borderRadius: "4px",
  borderColor: "#e5e5e5",
  backgroundColor: "#ffffff",
  fontSize: 12,
  height: 24,
}));
