import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Alert, Box, Grid, Skeleton, Slider, Typography } from "@mui/material";
import { debounce } from "lodash";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useData } from "../../context/data";
import { useEdit } from "../../context/edit";
import { Actor, Answer } from "../../types";

type PreferencesItem = Pick<Answer, "MPPQ_ID" | "UserWeight"> & {
  label: string;
};

type ActorAnswers = Pick<Actor, "SYS_A_ID"> & {
  Answers?: Omit<Answer, "Value">[];
};

type AnswerInput = Pick<Answer, "MPPQ_ID" | "UserWeight"> & {
  A_ID: string;
};

const QUERY_ACTOR = gql`
  query Actor($id: ID) {
    actor(ID: $id) {
      SYS_A_ID
      Answers {
        MPPQ_ID
        UserWeight
        UserFavorite
      }
    }
  }
`;

export const MUTATION_ACTOR_ANSWERS = gql`
  mutation UpdateActorAnswers($question: ActorQuestionInput) {
    ActorAnswer(question: $question) {
      MPPQ_ID
      UserWeight
    }
  }
`;

const Preferences = () => {
  const { questions } = useData();
  const { actorId, popProgress, pushProgress } = useEdit();

  const [actor, setActor] = useState<ActorAnswers | null | undefined>(null);
  const [items, setItems] = useState<PreferencesItem[]>([]);

  // gql.
  const [getActor] = useLazyQuery<
    { actor: ActorAnswers | null },
    { id: string }
  >(QUERY_ACTOR);

  const [updateAnswers] = useMutation<
    { ActorAnswer: Answer },
    { question: AnswerInput }
  >(MUTATION_ACTOR_ANSWERS);

  useEffect(() => {
    if (actorId) {
      // fetch data.
      getActor({
        variables: {
          id: actorId,
        },
      }).then((res) => {
        setActor(res?.data?.actor);
      });
    }
  }, [actorId, getActor]);

  /**
   * get question label / prefix.
   */
  const getQuestionLabel = useCallback<(qId: string) => string>(
    (qId: string) => {
      const question = questions.find((question) => question.MPPQ_ID === qId);
      return question?.Prefix ?? "";
    },
    [questions]
  );

  useEffect(() => {
    // get preferences list from actors
    let items: PreferencesItem[] = [];

    if (actor?.Answers) {
      actor.Answers.forEach((answer) => {
        if (answer.UserFavorite) {
          items.push({
            MPPQ_ID: answer.MPPQ_ID,
            UserWeight: answer.UserWeight,
            label: getQuestionLabel(answer.MPPQ_ID),
          });
        }
      });
    }

    setItems(items);
  }, [actor, getQuestionLabel]);

  const handleUpdate = useCallback(
    (questionId: string, weight: number) => {
      if (actor) {
        pushProgress();
        updateAnswers({
          variables: {
            question: {
              A_ID: actor.SYS_A_ID,
              MPPQ_ID: questionId,
              UserWeight: weight,
            },
          },
        }).finally(() => {
          popProgress();
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actor]
  );

  return (
    <Box p={2.5}>
      {actor !== null && items.length === 0 && (
        <Alert severity="info">
          Vælg en preference under profil fanen for at se mere
        </Alert>
      )}
      <Grid container spacing={4}>
        {actor === null ? (
          <SkeletonField />
        ) : (
          <>
            {items.map((item) => (
              <Grid key={item.MPPQ_ID} item xs={12} md={4}>
                <PreferenceField data={item} update={handleUpdate} />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
};

const PreferenceField = memo(
  ({
    data,
    update,
  }: {
    data: PreferencesItem;
    update: (questionId: string, weight: number) => void;
  }) => {
    const [value, setValue] = React.useState<number>(data.UserWeight);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleUpdate = useCallback(
      debounce((id: string, value: number) => {
        update(id, value);
      }, 1000),
      []
    );

    const handleChange = (_e: Event, value: number | number[]) => {
      setValue(value as number);
      handleUpdate(data.MPPQ_ID, value as number);
    };

    return (
      <Box sx={{ pb: 1 }}>
        <Typography variant="sectionTitle">{data.label}</Typography>
        <Box sx={{ pt: 5, px: 1 }}>
          <Slider
            value={value}
            onChange={handleChange}
            min={1}
            max={5}
            valueLabelDisplay="on"
          />
        </Box>
      </Box>
    );
  }
);

const SkeletonField = memo(() => {
  return (
    <>
      {[1, 2, 3, 4].map((i) => (
        <Grid key={i} item xs={12} md={4}>
          <Skeleton
            sx={{
              width: 100,
              mb: 3,
            }}
          />
          <Skeleton
            sx={{
              width: "100%",
              height: 20,
            }}
          />
        </Grid>
      ))}
    </>
  );
});

export default Preferences;
