import React, { createContext, useContext, useState } from "react";

type LayoutContextType = {
  showSearchForm: boolean;
  setShowSearchForm: (show: boolean) => void;
};

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

const LayoutProvider: React.FC = ({ children }) => {
  const [showSearchForm, setShowSearchForm] = useState(true);

  return (
    <LayoutContext.Provider value={{ showSearchForm, setShowSearchForm }}>
      {children}
    </LayoutContext.Provider>
  );
};

// custom hook
const useLayout = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }
  return context;
};

export { LayoutProvider, useLayout };
