import { AccountCircleOutlined } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useData } from "../../../context/data";

const Seller = ({ userId }: { userId: string | null }) => {
  const { users } = useData();

  const [name, setName] = useState("");

  useEffect(() => {
    if (userId && users) {
      const user = users.find((u) => u.U_ID === userId);
      if (user) {
        setName(user.Navn);
      }
    } else {
      setName("");
    }
  }, [userId, users]);

  return (
    <>
      {name && (
        <Stack direction="row">
          <AccountCircleOutlined />
          <Typography ml={1} fontSize={14}>
            {name}
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default memo(Seller);
