import { Box, Button, Card, CardContent, Divider, Grid } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useSearch } from "../../../context/search";
import FieldAutomatchSwitch from "./FieldAutomatchSwitch";
import FieldDate from "./FieldDate";
import FieldEconomy from "./FieldEconomy";
import FieldFacilities from "./FieldFacilities";
import FieldHousing from "./FieldHousing";
import FieldMatchSwitch from "./FieldMatchSwitch";
import FieldPeferences from "./FieldPeferences";
import FieldSelectSellers from "./FieldSelectSellers";
import FieldStatus from "./FieldStatus";
import FieldText from "./FieldText";

const SearchForm = () => {
  const { params, submit } = useSearch();
  // local states
  const [text, setText] = useState(params.filter.text);
  const [sellers, setSellers] = useState<string[]>([]);
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const [answers, setAnswers] = useState<string[]>(params.filter.answers);
  const [favorites, setFavorites] = useState<string[]>(params.filter.favorites);
  const [state, setState] = useState<any>({
    "3": { Min: 0, Max: 25000, step: 50, Values: [0, 25000] },
    "4": { Min: 0, Max: 250, step: 1, Values: [0, 250] },
    "5": { Min: 0, Max: 7, step: 1, Values: [0, 7] },
  });

  const [automatchSwitch, setAutomatchSwitch] = useState<number | null>(
    params.filter.automatchSwitch
  );
  const [matchSwitch, setMatchSwitch] = useState<number | null>(
    params.filter.matchSwitch
  );
  const handleSearchButton = () => {
    submit({
      filter: {
        text,
        sellers,
        favorites,
        answers,
        state,
        status,
        date,
        automatchSwitch,
        matchSwitch,
      },
      page: 1,
    });
  };

  const handleAnswers = useCallback((value: string) => {
    setAnswers((selected) => {
      if (selected.includes(value)) {
        return selected.filter((val) => val !== value);
      } else {
        return [...selected, value];
      }
    });
  }, []);

  const handleSliders = useCallback(
    (e: Event) => {
      // console.log(state);
      // console.log(e.target);
      const target = e.target as any;
      const { value, name } = target;

      setState({
        ...state,
        [name]: {
          Min: state[name].Min,
          Max: state[name].Max,
          step: state[name].step,
          Values: value,
        },
      });
    },
    [state]
  );

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2.5}>
          <Grid item xs={12} sm={3} xl={2}>
            <FieldText value={text} handleChange={setText} />
          </Grid>
          <Grid item xs={12} sm={3} xl={2}>
            <FieldSelectSellers
              selected={sellers}
              updateSelected={setSellers}
            />
          </Grid>
          <Grid item xs={12} sm={3} xl={2}>
            <FieldStatus value={status} onChange={setStatus} />
          </Grid>
          <Grid item xs={12} sm={3} xl={2}>
            <FieldDate value={date} onChange={setDate} />
          </Grid>
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        <Grid container columnSpacing={{ xs: 3, lg: 5, xl: 7 }}>
          <Grid item xs={12} md={6}>
            <FieldHousing
              selected={answers}
              onSelect={handleAnswers}
              onSlide={handleSliders}
              state={state}
            />
            {/* <FieldEconomy
              selected={answers}
              onSelect={handleAnswers}
              onSlide={handleSliders}
              state={state}
            /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <FieldFacilities
              selected={answers}
              onSelect={handleAnswers}
              onSlide={handleSliders}
              state={state}
            /> */}
            <FieldPeferences selected={favorites} onSelect={setFavorites} />
            <FieldMatchSwitch
              selected={matchSwitch}
              onChange={setMatchSwitch}
            />
            <FieldAutomatchSwitch
              selected={automatchSwitch}
              onChange={setAutomatchSwitch}
            />
          </Grid>
        </Grid>

        <Box pt={2.5} textAlign="right">
          <Button variant="contained" onClick={handleSearchButton}>
            START SØGNING
          </Button>
          {/* <SearchFormSubmit /> */}
        </Box>
      </CardContent>
    </Card>
  );
};

export default memo(SearchForm);
