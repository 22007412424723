import { gql, useMutation } from "@apollo/client";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useActorsDispatch } from "../../context/actors";
import { useData } from "../../context/data";
import { Actor } from "../../types";

type ModalUserProps = {
  open: boolean;
  handleClose: () => void;
  actor: Actor;
};

type DataInput = Pick<Actor, "SYS_A_ID" | "U_ID">;

export const MUTATION_ACTOR_USER = gql`
  mutation ActorUser($actor: ActorInput) {
    Actor(actor: $actor) {
      SYS_A_ID
      U_ID
    }
  }
`;

const ModalUser = ({ open, handleClose, actor }: ModalUserProps) => {
  // get users.
  const { users } = useData();

  // dispatch user.
  const { dispatch } = useActorsDispatch();

  // gql.
  const [updateData] = useMutation<
    { Actor: Pick<Actor, "SYS_A_ID" | "U_ID"> },
    { actor: DataInput }
  >(MUTATION_ACTOR_USER);

  const [disabled, setDisabled] = useState(false);

  // selected
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      setSelected(actor.U_ID);
    }
  }, [actor, open]);

  useEffect(() => {
    setSelected(actor.U_ID);
  }, [actor]);

  const handleSelect = (value: string) => () => {
    // toggle select.
    if (value === selected) {
      setSelected(null);
    } else {
      setSelected(value);
    }
  };

  const handleSave = async () => {
    if (actor) {
      setDisabled(true);
      await updateData({
        variables: {
          actor: {
            SYS_A_ID: actor.SYS_A_ID,
            U_ID: selected,
          },
        },
      }).then((res) => {
        // updated(res.data?.Actor.U_ID ? res.data?.Actor.U_ID : null);
        if (res.data?.Actor) {
          dispatch({
            type: "update",
            actor: res.data?.Actor,
          });
        }
      });
      setDisabled(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xs">
      <DialogContent
        sx={{
          height: 300,
          overflow: "auto",
          py: 0,
          px: 1,
        }}
      >
        <List>
          {users.map((user) => (
            <ListItem key={user.U_ID} disablePadding>
              <ListItemButton onClick={handleSelect(user.U_ID)} dense>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Checkbox
                    edge="start"
                    checked={selected === user.U_ID}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText>{user.Navn}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions sx={{ px: 3 }}>
        <Button
          color="primary"
          variant="contained"
          sx={{ px: 3 }}
          disabled={disabled}
          onClick={handleSave}
        >
          Gem
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUser;
