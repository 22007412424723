import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Snackbar } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import ModalAdd from "../components/add/ModalAdd";
import { useEdit } from "./edit";

type AddActorContextType = {
  add: () => void;
  success: (actorId: string) => void;
  error: (msg: string) => void;
};

// === create context
const AddActorContext = createContext<AddActorContextType | undefined>(
  undefined
);

// === context provider
const AddActorProvider: React.FC = ({ children }) => {
  const { open: openEdit } = useEdit();
  //   const { fetch } = useSearchActors();
  const [open, setOpen] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const add = () => {
    setOpen(true);
  };

  const success = (actorId: string) => {
    // refresh table.
    // fetch();

    // open edit modal.
    setOpen(false);
    if (actorId) {
      openEdit(actorId);
    }

    // show success msg
    setShowSuccessMsg(true);
  };

  const error = (msg: string) => {
    setErrorMsg(msg);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeSuccessMsg = () => {
    setShowSuccessMsg(false);
  };

  const closeErrroMsg = () => {
    setErrorMsg("");
  };

  return (
    <AddActorContext.Provider value={{ add, success, error }}>
      {children}
      <ModalAdd open={open} onClose={handleClose} />

      {/* success msg */}
      <Snackbar
        open={showSuccessMsg}
        autoHideDuration={6000}
        onClose={closeSuccessMsg}
        message="Added"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSuccessMsg}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      {/* errro msg */}
      <Snackbar
        open={errorMsg !== ""}
        autoHideDuration={6000}
        onClose={closeErrroMsg}
        message={errorMsg}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeErrroMsg}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </AddActorContext.Provider>
  );
};

// === custom hook
const useAddActor = () => {
  const context = useContext(AddActorContext);
  if (context === undefined) {
    throw new Error("useAddActor must be used within a AddActorProvider");
  }
  return context;
};

export { AddActorProvider, useAddActor };
