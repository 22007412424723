import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import React, { memo, useState } from "react";

const FieldMatchSwitch = ({
  selected,
  onChange,
}: {
  selected: number | null;
  onChange: (checked: number | null) => void;
}) => {
  const [checked, setChecked] = useState<number | null>(
    selected === null ? -1 : selected
  );
  const switchHandler = (
    event: React.MouseEvent<HTMLElement>,
    newValue: number
  ) => {
    setChecked(newValue);
    onChange(newValue === -1 ? null : newValue);
  };

  return (
    <Box mb={4}>
      <Typography variant="sectionTitle" mb={2.5}>
        Matches
      </Typography>
      <Box>
        <ToggleButtonGroup
          value={checked}
          exclusive
          onChange={switchHandler}
          color="primary"
        >
          <ToggleButton value={-1} aria-label="Alle">
            Alle
          </ToggleButton>
          <ToggleButton value={0} aria-label="Ingen Usete">
            Ingen Usete
          </ToggleButton>
          <ToggleButton value={1} aria-label="Usete">
            Usete
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default memo(FieldMatchSwitch);
