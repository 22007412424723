import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import React, { memo } from "react";

const FieldText = ({
  value,
  handleChange,
}: {
  value: string;
  handleChange: (value: string) => void;
}) => {
  return (
    <TextField
      label="Søg profil"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={(ev) => {
        handleChange(ev.target.value);
      }}
    />
  );
};

export default memo(FieldText);
