import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { store } from "./redux/store";
import routes from "./routes";
import createTheme from "./theme";

function App() {
  const content = useRoutes(routes);

  return (
    <Provider store={store}>
      <ThemeProvider theme={createTheme()}>
        <CssBaseline />
        {content}
      </ThemeProvider>
    </Provider>
  );
}

export default App;
