import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useData } from "../context/data";

export const FieldFirstName = memo((props: TextFieldProps) => {
  return <TextField label="Fornavn" fullWidth {...props} />;
});

export const FieldLastName = memo((props: TextFieldProps) => {
  return <TextField label="Efternavn" fullWidth {...props} />;
});

export const FieldEmail = memo((props: TextFieldProps) => {
  return <TextField label="E-mail" type="email" name="email" {...props} />;
});

export const FieldPhone = memo((props: TextFieldProps) => {
  return <TextField label="Telefon" {...props} />;
});

export const UserName = memo((props: TextFieldProps) => {
  return <TextField label="Brugernavn" fullWidth {...props} />;
});

export const FieldStreet = memo((props: TextFieldProps) => {
  return <TextField label="Vejnavn" fullWidth {...props} />;
});

export const FieldHouseNumber = memo((props: TextFieldProps) => {
  return <TextField label="Nr." fullWidth {...props} />;
});

export const FieldFloor = memo((props: TextFieldProps) => {
  return <TextField label="Etage" fullWidth {...props} />;
});

export const FieldSideDoor = memo((props: TextFieldProps) => {
  return <TextField label="Side / Dør" fullWidth {...props} />;
});

export const FieldZip = memo((props: TextFieldProps) => {
  return <TextField label="Postnummer" fullWidth {...props} />;
});

export const FieldCity = memo((props: TextFieldProps) => {
  return <TextField label="By" fullWidth {...props} />;
});

type FieldCountryProps = {
  name: string;
  value: string | null;
  onChange: (value: string | null) => void;
  disabled?: boolean;
};

export const FieldCountry = memo(
  ({ name, value, onChange, disabled }: FieldCountryProps) => {
    const { countries } = useData();

    const [options, setOptions] = useState<string[]>([]);

    useEffect(() => {
      setOptions(countries.map((c) => c.ISO_3166_DA));
    }, [countries]);

    const handleChange = (_e: any, newValue: string | null) => {
      onChange(newValue);
    };

    return (
      <>
        <Autocomplete
          options={options}
          value={value}
          renderInput={(params) => (
            <TextField {...params} name={name} label="Land" />
          )}
          onChange={handleChange}
          disabled={disabled}
        />
      </>
    );
  }
);

export const InactiveActive = memo(
  ({
    portal,
    autoMatch,
    onChangePortal,
    onChangeAutoMatch,
    disabled,
  }: {
    portal: boolean;
    autoMatch: boolean;
    onChangePortal: (checked: boolean) => void;
    onChangeAutoMatch: (checked: boolean) => void;
    disabled?: boolean;
  }) => {
    const handleActive = useCallback(
      (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onChangePortal(checked);
      },
      [onChangePortal]
    );

    const handleAutoMatch = useCallback(
      (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onChangeAutoMatch(checked);
      },
      [onChangeAutoMatch]
    );

    return (
      <Box py={3.5} px={3}>
        <Grid container columnSpacing={3} justifyContent="space-between">
          <Grid item md={4} lg={3} xs={12}>
            <Box mb={{ md: 0, xs: 2 }}>
              <Typography variant="sectionTitle" mb={1}>
                Inaktiv / aktiv
              </Typography>
              <Typography variant="sectionDesc">
                Se om aktøren har aktiveret sin adgang til medlemsportalen og om
                automatisk matching er tændt.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} lg={7} xs={12}>
            <Stack direction="row">
              <FormGroup sx={{ mr: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={portal}
                      onChange={handleActive}
                      disabled={disabled}
                    />
                  }
                  label="Aktiv på portal"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={autoMatch}
                      onChange={handleAutoMatch}
                      disabled={disabled}
                    />
                  }
                  label="Aktiv automatching"
                />
              </FormGroup>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

const options = ["Option 1", "Option 2"];

export function ControllableStates() {
  const [value, setValue] = React.useState<string | null>(options[0]);
  const [inputValue, setInputValue] = React.useState("");

  return (
    <div>
      <div>{`value: ${value !== null ? `'${value}'` : "null"}`}</div>
      <div>{`inputValue: '${inputValue}'`}</div>
      <br />
      <Autocomplete
        value={value}
        onChange={(event: any, newValue: string | null) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Controllable" />}
      />
    </div>
  );
}
