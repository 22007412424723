import { gql, useMutation } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Snackbar, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import { Actor } from "../../types";

const SEND_PASSWORD = gql`
  mutation Actor($actor: ActorInput) {
    Actor(actor: $actor) {
      SYS_A_ID
    }
  }
`;

const CREATE_AS_MEMBER = gql`
  mutation Actor($actor: ActorInput) {
    Actor(actor: $actor) {
      SYS_A_ID
    }
  }
`;

const StatusNonMemberAction = memo(({ actorId }: { actorId: string }) => {
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);

  // gql.
  const [createAsMember, { loading }] = useMutation<
    { Actor: Partial<Actor> },
    { actor: { SYS_A_ID: string; CreateAsMember: boolean } }
  >(CREATE_AS_MEMBER);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleSendButton = () => {
    createAsMember({
      variables: {
        actor: {
          SYS_A_ID: actorId,
          CreateAsMember: true,
        },
      },
    }).then((res) => {
      setOpen(false);
      setOpenInfo(true);
    });
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        disabled={open}
        onClick={handleOpen}
      >
        OPRET SOM MEDLEM
      </Button>
      {open && (
        <Box pt={4} maxWidth={340}>
          <Typography component="div" variant="h5" mb={1}>
            Send adgangskode ved oprettelse
          </Typography>
          {/* <Typography>
            Ønsker du at sende mail til Flemming Sørensen på email med
            notifikation om adgangskode?
          </Typography> */}
          <Box pt={2} />
          <Button variant="default" sx={{ mr: 2 }} onClick={handleClose}>
            NEJ
          </Button>
          <Button
            variant="contained"
            onClick={handleSendButton}
            disabled={loading}
          >
            JA
          </Button>
        </Box>
      )}

      <Snackbar
        open={openInfo}
        autoHideDuration={6000}
        onClose={handleCloseInfo}
        message="Sent"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseInfo}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
});

const StatusMemberAction = memo(({ actorId }: { actorId: string }) => {
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);

  // gql.
  const [sendPassword, { loading }] = useMutation<
    { Actor: Partial<Actor> },
    { actor: { SYS_A_ID: string; SendPassword: boolean } }
  >(SEND_PASSWORD);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleSendButton = () => {
    sendPassword({
      variables: {
        actor: {
          SYS_A_ID: actorId,
          SendPassword: true,
        },
      },
    }).then((res) => {
      setOpen(false);
      setOpenInfo(true);
    });
  };

  return (
    <>
      <Button variant="contained" disabled={open} onClick={handleOpen}>
        SEND ADGANGSKODE
      </Button>
      {open && (
        <Box pt={4} maxWidth={340}>
          <Typography component="div" variant="h5" mb={1}>
            Send adgangskode
          </Typography>
          {/* <Typography>
            Ønsker du at sende mail til Flemming Sørensen på email med
            notifikation om en ny adgangskode?
          </Typography> */}
          <Box pt={2} />
          <Button variant="default" sx={{ mr: 2 }} onClick={handleClose}>
            NEJ
          </Button>
          <Button
            variant="contained"
            onClick={handleSendButton}
            disabled={loading}
          >
            JA
          </Button>
        </Box>
      )}

      <Snackbar
        open={openInfo}
        autoHideDuration={3000}
        onClose={handleCloseInfo}
        message="Sent"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseInfo}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
});

export { StatusMemberAction, StatusNonMemberAction };
