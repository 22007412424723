import { gql, useLazyQuery } from "@apollo/client";
import React, { createContext, useCallback, useContext, useState } from "react";
import Modal from "../components/edit/Modal";
import { Actor } from "../types";
import { useActorsDispatch } from "./actors";

// === gql
const QUERY_ACTOR = gql`
  query Actor($id: ID) {
    actor(ID: $id) {
      SYS_A_ID
      U_ID
      Firstname
      Lastname
      Username
      Street
      HouseNo
      Floor
      SideDoor
      ZipCode
      City
      Country
      PhonePrivate
      UnseenUnits
      AutoMatch
      MatchedUnitsCount
      Created
      Active
      TotalActors
      Activity {
        ID
        rolle
        Created
        Seller {
          Navn
        }
        Campaign {
          Name
          Date
          Time
        }
        ChangedBy {
          Navn
        }
      }
      Answers {
        MPPQ_ID
        UserFavorite
      }
    }
  }
`;

// === types

type EditContextType = {
  open: (actorId: string) => void;
  actorId: string | null;
  pushProgress: () => void;
  popProgress: () => void;
};

// === create context
const EditContext = createContext<EditContextType | undefined>(undefined);

// === context provider
const EditProvider: React.FC = ({ children }) => {
  const { dispatch } = useActorsDispatch();
  const [openModal, setOpenModal] = useState(false);

  const [actorId, setActorId] = useState<string | null>(null);

  const [progressStack, setProgressStack] = useState<boolean[]>([]);
  const [edited, setEdited] = useState<boolean>(false);

  // gql.
  const [getActor] = useLazyQuery<{ actor: Actor }, { id: string }>(
    QUERY_ACTOR
  );

  const updateActorRow = useCallback(() => {
    if (actorId) {
      getActor({
        variables: {
          id: actorId,
        },
      }).then((res) => {
        if (res?.data?.actor) {
          dispatch({ type: "update", actor: res?.data?.actor });
        }
      });
    }
  }, [actorId, dispatch, getActor]);

  const open = useCallback((actorId: string) => {
    setOpenModal(true);
    setActorId(actorId);
    setEdited(false);
  }, []);

  const handleClose = () => {
    if (edited) {
      updateActorRow();
    }
    setOpenModal(false);
  };

  const pushProgress = () => {
    setProgressStack((progress) => [...progress, true]);
    setEdited(true);
  };

  const popProgress = () => {
    setProgressStack((progress) => {
      let p = [...progress];
      p.pop();
      return p;
    });
  };

  return (
    <EditContext.Provider value={{ open, actorId, pushProgress, popProgress }}>
      {children}
      <Modal
        open={openModal}
        handleClose={handleClose}
        inProgress={progressStack.length > 0}
      />
    </EditContext.Provider>
  );
};

// ===  custom hook
const useEdit = () => {
  const context = useContext(EditContext);
  if (context === undefined) {
    throw new Error("useEdit must be used within a EditProvider");
  }
  return context;
};

export { EditProvider, useEdit };
