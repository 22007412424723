import { ChipProps } from "@mui/material";
import React, { memo } from "react";
import { StyledChipOption } from "../../../theme/styled-components";
import FavoriteIconButton from "./FavoriteIconButton";

type OptionChipStarProps = ChipProps & {
  label: string;
  value: string;
  selected: boolean;
  isFavorite: boolean;
  handleClick: (value: string) => void;
  handleFavorite: () => void;
};

const OptionChipStar = memo(
  ({
    label,
    value,
    selected,
    isFavorite,
    handleClick,
    handleFavorite,
    sx,
    ...props
  }: OptionChipStarProps) => {
    const handleClickFavorite = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.stopPropagation();
      handleFavorite();
    };

    const handleClickChip = () => {
      handleClick(value);
    };

    return (
      <StyledChipOption
        label={label}
        sx={{
          backgroundColor: selected ? "#e5e5e5" : "#ffffff",
          ...sx,
        }}
        variant="outlined"
        icon={
          <FavoriteIconButton
            isActive={isFavorite}
            onClick={handleClickFavorite}
          />
        }
        onClick={handleClickChip}
        {...props}
      />
    );
  }
);

export default OptionChipStar;
